import React from 'react';
import { Link } from 'gatsby';

import './ServiceBox.scss';

const ServiceBox = ({ image, title, text, btnLink, btnText, imageWidth }) => {
  return (
    <div className="th-service-box">
      <div className="th-service-image">
        <img width={imageWidth ? imageWidth : ""} className="img-fluid" src={image} alt="" />
      </div>
      <div className="th-service-content">
        <h5 className="th-service-content__header">{title}</h5>
        <p className="th-service-content__paragraph">{text}</p>
        <Link className="th-service-content__anchor" to={btnLink}>
          {btnText}
        </Link>
      </div>
    </div>
  );
};

export default ServiceBox;
