import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'gatsby';

import SlickSlider from './SlickSlider';
import SliderData from './slider-data.json';

import './HeroSlider.scss';

const NextArrow = ({ className, onClick }) => {
  return (
    <button type="button" className={className} onClick={onClick}>
      <MdKeyboardArrowRight />
    </button>
  );
};

const PrevArrow = ({ className, onClick }) => {
  return (
    <button type="button" className={className} onClick={onClick}>
      <MdKeyboardArrowLeft />
    </button>
  );
};

const HeroSlider = () => {
  const [active, setActive] = useState(0);

  useEffect(() => {
    const header = document.querySelector(`.slick-active .th-slider-content__header`);
    const paragraph = document.querySelector(`.slick-active .th-slider-content__paragraph`);
    const button = document.querySelector(`.slick-active .th-slider-content__buttons a`);
    const animationClass = 'th-fadeIn';

    header && header.classList.add(animationClass);
    paragraph && paragraph.classList.add(animationClass);
    button && button.classList.add(animationClass);

    return () => {
      header && header.classList.remove(animationClass);
      paragraph && paragraph.classList.remove(animationClass);
      button && button.classList.remove(animationClass);
    };
  }, [active]);

  const handleChange = (current) => {
    setActive(current);
  };

  const settings = {
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    afterChange: (current) => handleChange(current),
    dots: false,
    infinite: true,
    nextArrow: <NextArrow />,
    pauseOnHover: false,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1360,
        settings: {
          arrows: false,
          dots: false,
        },
      },
    ],
    speed: 1000,
    // fade: true, // alternate animation
    // useCSS: false,
  };

  return (
    <div className="th-hero-slider container-lg">
      <SlickSlider settings={settings}>
        {SliderData.map((item) => (
          <div key={item.id}>
            <div className={`th-slider-item th-slider-item--${item.id}`} style={{ backgroundImage: `url(${item.bg})` }}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="th-slider-content-wrapper">
                      <div className="th-slider-content">
                        <h2
                          className={`th-slider-content__header slider-content__header--${item.id}`}
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                        <p className="th-slider-content__paragraph">{item.text}</p>
                        {item.btnLink && (
                          <div className="th-slider-content__buttons">
                            <Link to={item.btnLink}>{item.btnText}</Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </SlickSlider>
    </div>
  );
};

export default HeroSlider;
