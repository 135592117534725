import React from 'react';
import { Link } from 'gatsby';

import ServiceBox from './ServiceBox';
import ServiceData from './service-data.json';

import './ServiceSection.scss';

const ServiceSection = () => {
  return (
    <div className="th-service-section">
      <div className="container-lg">
        <div className="row th-service-section__row">
          <div className="col-md-9">
            <h5 className="th-service-title-small">&nbsp;</h5>
            <h2 className="th-service-title-large">Our main areas of activity</h2>
          </div>
          <div className="col-md-3">
            {/* <div className="text-md-right th-service-title-button">
              <Link to="/about ">Read More</Link>
            </div> */}
          </div>
        </div>
        <div className="row th-service-section__row">
          {ServiceData.map((item) => (
            <div className="col-md-4 th-service-box-wrapper" key={item.id}>
              <ServiceBox
                imageWidth={item.imageWidth}
                image={item.image}
                title={item.title}
                text={item.text}
                btnText={item.btnText}
                btnLink={item.btnLink}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
