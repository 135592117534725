import React from 'react';
import Slick from 'react-slick';

import './SlickSlider.scss';

const SlickSlider = ({ children, settings }) => {
  return <Slick {...settings}>{children}</Slick>;
};

export default SlickSlider;
