import React from 'react';
import { MdMonetizationOn, MdPersonAdd, MdPowerSettingsNew } from 'react-icons/md';

import FeaturedBox from './FeaturedBox';

import './FeaturedSection.scss';

const FeaturedSection = () => {
  return (
    <div className="th-featured-section">
      <div className="container-lg">
        <div className="row no-gutters th-featured-section__row">
          <div className="col-md-4">
            <FeaturedBox
              icon={<MdPersonAdd size={24} />}
              title="Solve IT staffing challenges"
              description="Build cross-functional teams of experts fast and adapt them to changing business requirements."
            />
          </div>
          <div className="col-md-4">
            <FeaturedBox
              dark="true"
              icon={<MdPowerSettingsNew size={24} />}
              title="Build new products"
              description="Deliver innovative products and services, and stay ahead of competition."
            />
          </div>
          <div className="col-md-4">
            <FeaturedBox
              icon={<MdMonetizationOn size={24} />}
              title="Maximize profit"
              description="Cost-effective product development. Outsource SW development to specialists in getting things done."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedSection;
