import React from 'react';

import './FeaturedBox.scss';

const FeaturedBox = ({ icon, title, description, dark = false }) => {
  return (
    <div className={`th-featured-box${dark ? ' th-featured-box--dark' : ''}`}>
      <div className="th-featured-icon">
        <div className="th-featured-icon__inner">{icon}</div>
      </div>
      <div className="th-featured-content">
        <h3 className="th-featured-content__header">{title}</h3>
        <p className="th-featured-content__paragraph">{description}</p>
      </div>
    </div>
  );
};

export default FeaturedBox;
